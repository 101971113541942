import "pfkl-vtabs/vtabs/vtabs.min.js";
import "pfkl-vcollapse/vcollapse/vcollapse.min.js";
import "slick-carousel-latest";

// Maps
import "./js/jvectormap/jquery-jvectormap.min.js";
import "./js/map/jquery-jvectormap-world-mill"; // Map file
import "./js/home-worldmap";
import "./js/charts";

import "./js/impactStories.js";
import "./js/slick.js";

// Check if element has entered viewport
$.fn.isInViewport = function () {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();

  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

$(function () {
  console.log("Ready");

  // Smooth scrolling to anchor # ///////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////////////////////
  $("a[href*=\\#]:not([href=\\#])").on("click", function () {
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
        $("html,body").animate(
          {
            scrollTop: target.offset().top,
          },
          1000,
        );
        return false;
      }
    }
  });
  // Smooth scrolling to anchor # ///////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////// END

  // HEADER  UX (Mobile) ////////////////////////////////////////////////////////////
  // ////////////////////////////////////////////////////////////////////////////////
  $("#button-open-mobile-menu").on("click", function () {
    $("#mobile-menu").fadeIn();
  });

  $("#button-close-mobile-menu").on("click", function () {
    $("#mobile-menu").fadeOut();
  });

  $(".nav-mobile nav ul li a").on("click", function (e) {
    $("#mobile-menu").fadeOut();
  });
  // HEADER  UX (Mobile) ////////////////////////////////////////////////////////////
  // //////////////////////////////////////////////////////////////////////////// END

  // MODALS //////////////////////////////////////

  // bind the click event of modal trigger to document
  // fixes modal not opening issues if trigger is inside
  // elements that are hidden in DOM when page loads
  $(document).on("click", ".button-open-modal", function (e) {
    e.preventDefault();
    const target = $($(this).data("target"));

    if (target.length > 0) {
      $("#overlay-mask").fadeIn();
      target.delay(500).fadeIn();
      $("body").addClass("overflow-hidden");
    }
  });

  $(".button-close-modal").on("click", function () {
    const target = $($(this).data("target"));

    target.fadeOut();
    $("#overlay-mask").delay(500).fadeOut();
    $("body").removeClass("overflow-hidden");
  });

  // clicking on "overlay" will close modal
  $(".modal-wrapper").on("click", function (e) {
    e.stopPropagation();

    $(this).fadeOut();
    $("#overlay-mask").delay(500).fadeOut();
    $("body").removeClass("overflow-hidden");
  });

  // prevent modal closing when clicking on content inside modal
  $(".content-modal").on("click", function (e) {
    e.stopPropagation();
  });
  // END MODALS //////////////////////////////////////

  // Count up numbers //////////////////////////////////////////////////
  let countUpElements = [];
  const countUp = $(".count-up-numbers");

  countUp.each(function (i) {
    const elm = $(this);
    const incrementSpeed = elm.data("increment")
      ? parseInt(elm.data("increment"))
      : 200;
    const value = parseInt(elm.html().replace(/,/g, ""));
    elm.attr("data-index", i);
    elm.attr("data-value", value);

    // wrap it with span tag to maintain the initial width of the element
    // so that the animation looks smoother and not "shaking" the layout
    elm.wrapInner("<span></span>");

    countUpElements[i] = {
      countStarted: false,
      count: 0,
      value: value,

      // increase the increment based on the value being counted
      incrementBy: Math.ceil(value / incrementSpeed),
    };
  });

  $(window).on("scroll", function () {
    countUp.each(function (i) {
      if ($(this).isInViewport()) {
        const currentElm = countUpElements.find((val, idx) => idx === i);
        if (!currentElm.countStarted) {
          countUpElements[i].countStarted = true;
          countViews(i);
        }
      }
    });
  });

  function countViews(i) {
    if (countUpElements[i]) {
      const currentElm = countUpElements[i];
      const countTo = currentElm.value;
      const currentCount = currentElm.count;
      const newCount = currentCount + currentElm.incrementBy;

      countUpElements[i].count = newCount;
      const formattedValue = newCount.toLocaleString('en-GB'); // to get the commas
      $(`.count-up-numbers[data-index=${i}] span`).html(formattedValue);

      if (currentCount < countTo) {
        requestAnimationFrame(() => countViews(i));
      } else {
        $(`.count-up-numbers[data-index=${i}] span`).html(
          countTo.toLocaleString(),
        );
      }
    }
  }
  // Count up numbers END //////////////////////////////////////////////////

  // Progress bar animation //////////////////////////////////////////////////
  let themeProgressBars = [];
  const themeBars = $(".theme-progress-bar");

  themeBars.each(function (i) {
    const theme = $(this);
    const value = theme.data("value");
    const total = theme.data("total");
    const formattedValue = value ? parseInt(value.replace(/,/g, "")) : 0;
    const formattedTotal = total ? parseInt(total.replace(/,/g, "")) : 0;
    const themePercentage =
      formattedTotal > 0 ? formattedValue / formattedTotal : 0;
    const color = theme.data("color");
    console.log(`item ${i}:`, {
      value,
      formattedValue,
      total,
      formattedTotal,
      themePercentage,
    });

    theme.attr("data-index", i);
    if (color) {
      theme.find(".progress-bar__bar").css("background-color", color);
    }

    themeProgressBars[i] = {
      animStarted: false,
      currentPercentage: 0,
      totalPercentage: themePercentage,
    };
  });

  $(window).on("scroll", function () {
    themeBars.each(function (i) {
      if ($(this).isInViewport()) {
        const currentElm = themeProgressBars.find((val, idx) => idx === i);
        if (!currentElm.animStarted) {
          themeProgressBars[i].animStarted = true;
          requestAnimationFrame((timestamp) =>
            animateProgressBar(i, timestamp),
          );
        }
      }
    });
  });

  let start;
  function animateProgressBar(i, timestamp) {
    if (!start) start = timestamp;
    const progress = Math.min((timestamp - start) / 1000, 1); // Progress from 0 to 1
    const totalPercentage = themeProgressBars[i].totalPercentage;
    const progressPercentage = totalPercentage * progress * 100;
    let widthPercentage = `${progressPercentage.toFixed(2)}%`;

    // if (progressPercentage < 1) {
    //   widthPercentage = `${progressPercentage.toFixed(2)}%`;
    // } else {
    //   widthPercentage = `${Math.round(progressPercentage)}%`;
    // }

    const barElem = $(`.theme-progress-bar[data-index=${i}]`);

    const rowBar = barElem.find(".progress-bg").attr("data-width");

    barElem.find(".progress-bar__bar").css("width", widthPercentage);
    barElem.find(".progress-bar__percentage").html(widthPercentage);

    barElem.find(".progress-bg").css("width", rowBar + '%');

    if (progress < 1) {
      requestAnimationFrame((timestamp) => animateProgressBar(i, timestamp));
    }
  }
  // Progress bar animation END //////////////////////////////////////////////////

  $(".mission-collapsible").vCollapse({
    any: false,
    onLoad: 0,
    speed: 300,
    easing: "ease-in-out",
    // autoScroll: true,
  });
});
///////////////////////////////////////////////////////////////////////////////
