export const mapData = {
  arCountries: [
    {name:"Sudan",project_classification:"Existing and planned projects",country_code:"SD",permalink:"https://ideaint.poetfarmer.com/country/sudan"}
    ,{name:"Moldova",project_classification:"Existing and planned projects",country_code:"MD",permalink:"https://ideaint.poetfarmer.com/country/moldova"},
    {name:"Ukraine",project_classification:"Existing and planned projects",country_code:"UA",permalink:"https://ideaint.poetfarmer.com/country/ukraine"},
    {name:"Georgia",project_classification:"Existing and planned projects",country_code:"GE",permalink:"https://ideaint.poetfarmer.com/country/georgia"},
    {name:"Mexico",project_classification:"Existing and planned projects",country_code:"MX",permalink:"https://ideaint.poetfarmer.com/country/mexico"},
    {name:"Bhutan",project_classification:"Existing and planned projects",country_code:"BT",permalink:"https://ideaint.poetfarmer.com/country/bhutan"},
    {name:"Chile",project_classification:"Existing and planned projects",country_code:"CL",permalink:"https://ideaint.poetfarmer.com/country/chile"},
    {name:"Myanmar ",project_classification:"Existing and planned projects",country_code:"MM",permalink:"https://ideaint.poetfarmer.com/country/myanmar"},
    {name:"Fiji",project_classification:"Existing and planned projects",country_code:"FJ",permalink:"https://ideaint.poetfarmer.com/country/fiji"},
    {name:"Australia",project_classification:"Existing and planned projects",country_code:"AU",permalink:"https://ideaint.poetfarmer.com/country/australia"},
    {name:"Tunisia",project_classification:"Existing and planned projects",country_code:"TN",permalink:"https://ideaint.poetfarmer.com/country/tunisia"},
    {name:"Kenya",project_classification:"Existing and planned projects",country_code:"KE",permalink:"https://ideaint.poetfarmer.com/country/kenya"},
    {name:"Paraguay",project_classification:"Existing and planned projects",country_code:"PY",permalink:"https://ideaint.poetfarmer.com/country/paraguay"},
    {name:"Nepal",project_classification:"Existing and planned projects",country_code:"NP",permalink:"https://ideaint.poetfarmer.com/country/nepal"},
    {name:"Mozambique",project_classification:"Existing and planned projects",country_code:"MZ",permalink:"https://ideaint.poetfarmer.com/country/mozambique"},
    {name:"Netherlands",project_classification:"Existing and planned projects",country_code:"NL",permalink:"https://ideaint.poetfarmer.com/country/netherlands"},
    {name:"Albania",project_classification:"Existing and planned projects",country_code:"AL",permalink:"https://ideaint.poetfarmer.com/country/albania"},
    {name:"Argentina",project_classification:"Existing and planned projects",country_code:"AR",permalink:"https://ideaint.poetfarmer.com/country/argentina"},
    {name:"Armenia",project_classification:"Existing and planned projects",country_code:"AM",permalink:"https://ideaint.poetfarmer.com/country/armenia"},
    {name:"Belgium",project_classification:"Existing and planned projects",country_code:"BE",permalink:"https://ideaint.poetfarmer.com/country/belgium"},
    {name:"Bosnia & Herzegovina",project_classification:"Existing and planned projects",country_code:"BA",permalink:"https://ideaint.poetfarmer.com/country/bosnia-herzegovina"},
    {name:"Botswana",project_classification:"Existing and planned projects",country_code:"BW",permalink:"https://ideaint.poetfarmer.com/country/botswana"},
    {name:"Burkina Faso",project_classification:"Existing and planned projects",country_code:"BF",permalink:"https://ideaint.poetfarmer.com/country/burkina-faso"},
    {name:"Cameroon",project_classification:"Existing and planned projects",country_code:"CM",permalink:"https://ideaint.poetfarmer.com/country/cameroon"},
    {name:"Canada",project_classification:"Existing and planned projects",country_code:"CA",permalink:"https://ideaint.poetfarmer.com/country/canada"},
    {name:"Chad",project_classification:"Existing and planned projects",country_code:"TD",permalink:"https://ideaint.poetfarmer.com/country/chad"},
    {name:"Colombia",project_classification:"Existing and planned projects",country_code:"CO",permalink:"https://ideaint.poetfarmer.com/country/colombia"},
    {name:"Costa Rica",project_classification:"Existing and planned projects",country_code:"CR",permalink:"https://ideaint.poetfarmer.com/country/costa-rica"},
    {name:"Dominican Republic",project_classification:"Existing and planned projects",country_code:"DO",permalink:"https://ideaint.poetfarmer.com/country/dominican-republic"},
    {name:"El Salvador",project_classification:"Existing and planned projects",country_code:"SV",permalink:"https://ideaint.poetfarmer.com/country/el-salvador"},
    {name:"Equatorial Guinea",project_classification:"Existing and planned projects",country_code:"GQ",permalink:"https://ideaint.poetfarmer.com/country/equatorial-guinea"},
    {name:"Ethiopia",project_classification:"Existing and planned projects",country_code:"ET",permalink:"https://ideaint.poetfarmer.com/country/ethiopia"},
    {name:"Gabon",project_classification:"Existing and planned projects",country_code:"GA",permalink:"https://ideaint.poetfarmer.com/country/gabon"},
    {name:"Gambia",project_classification:"Existing and planned projects",country_code:"GM",permalink:"https://ideaint.poetfarmer.com/country/gambia"},
    {name:"Guatemala",project_classification:"Existing and planned projects",country_code:"GT",permalink:"https://ideaint.poetfarmer.com/country/guatemala"},
    {name:"Guinea",project_classification:"Existing and planned projects",country_code:"GN",permalink:"https://ideaint.poetfarmer.com/country/guinea"},
    {name:"Guinea-Bissau",project_classification:"Existing and planned projects",country_code:"GW",permalink:"https://ideaint.poetfarmer.com/country/guinea-bissau"},
{name:"India",project_classification:"Existing and planned projects",country_code:"IN",permalink:"https://ideaint.poetfarmer.com/country/india"},
{name:"Indonesia",project_classification:"Existing and planned projects",country_code:"ID",permalink:"https://ideaint.poetfarmer.com/country/indonesia"},
{name:"Iraq",project_classification:"Existing and planned projects",country_code:"IQ",permalink:"https://ideaint.poetfarmer.com/country/iraq"},
{name:"Jordan",project_classification:"Existing and planned projects",country_code:"JO",permalink:"https://ideaint.poetfarmer.com/country/jordan"},
{name:"Lebanon",project_classification:"Existing and planned projects",country_code:"LB",permalink:"https://ideaint.poetfarmer.com/country/lebanon"},
{name:"Libya",project_classification:"Existing and planned projects",country_code:"LY",permalink:"https://ideaint.poetfarmer.com/country/libya"},
{name:"Malawi",project_classification:"Existing and planned projects",country_code:"MW",permalink:"https://ideaint.poetfarmer.com/country/malawi"},
{name:"Malaysia",project_classification:"Existing and planned projects",country_code:"MY",permalink:"https://ideaint.poetfarmer.com/country/malaysia"},
{name:"Mali",project_classification:"Existing and planned projects",country_code:"ML",permalink:"https://ideaint.poetfarmer.com/country/mali"},
{name:"Mauritania",project_classification:"Existing and planned projects",country_code:"MR",permalink:"https://ideaint.poetfarmer.com/country/mauritania"},
{name:"Mongolia",project_classification:"Existing and planned projects",country_code:"MN",permalink:"https://ideaint.poetfarmer.com/country/mongolia"},
{name:"Nigeria",project_classification:"Existing and planned projects",country_code:"NG",permalink:"https://ideaint.poetfarmer.com/country/nigeria"},
{name:"North Macedonia",project_classification:"Existing and planned projects",country_code:"MK",permalink:"https://ideaint.poetfarmer.com/country/north-macedonia"},
{name:"Palestine",project_classification:"Existing and planned projects",country_code:"PS",permalink:"https://ideaint.poetfarmer.com/country/palestine"},
{name:"Philippines",project_classification:"Existing and planned projects",country_code:"PH",permalink:"https://ideaint.poetfarmer.com/country/philippines"},
{name:"Serbia",project_classification:"Existing and planned projects",country_code:"RS",permalink:"https://ideaint.poetfarmer.com/country/serbia"},
{name:"Sierra Leone",project_classification:"Existing and planned projects",country_code:"SL",permalink:"https://ideaint.poetfarmer.com/country/sierra-leone"},
{name:"Solomon Islands",project_classification:"Existing and planned projects",country_code:"SB",permalink:"https://ideaint.poetfarmer.com/country/solomon-islands"},
{name:"Somalia",project_classification:"Existing and planned projects",country_code:"SO",permalink:"https://ideaint.poetfarmer.com/country/somalia"},
{name:"South Sudan",project_classification:"Existing and planned projects",country_code:"SS",permalink:"https://ideaint.poetfarmer.com/country/south-sudan"},
{name:"Sri Lanka",project_classification:"Existing and planned projects",country_code:"LK",permalink:"https://ideaint.poetfarmer.com/country/sri-lanka"},
{name:"Sweden",project_classification:"Existing and planned projects",country_code:"SE",permalink:"https://ideaint.poetfarmer.com/country/sweden"},
{name:"Tanzania",project_classification:"Existing and planned projects",country_code:"TZ",permalink:"https://ideaint.poetfarmer.com/country/tanzania"},
{name:"Thailand",project_classification:"Existing and planned projects",country_code:"TH",permalink:"https://ideaint.poetfarmer.com/country/thailand"},
{name:"Timor-Leste",project_classification:"Existing and planned projects",country_code:"TL",permalink:"https://ideaint.poetfarmer.com/country/timor-leste"},
{name:"United States",project_classification:"Existing and planned projects",country_code:"US",permalink:"https://ideaint.poetfarmer.com/country/united-states"},
{name:"Uruguay",project_classification:"Existing and planned projects",country_code:"UY",permalink:"https://ideaint.poetfarmer.com/country/uruguay"},
{name:"Venezuela",project_classification:"Existing and planned projects",country_code:"VE",permalink:"https://ideaint.poetfarmer.com/country/venezuela"},
{name:"Yemen",project_classification:"Existing and planned projects",country_code:"YE",permalink:"https://ideaint.poetfarmer.com/country/yemen"},
{name:"Zambia",project_classification:"Existing and planned projects",country_code:"ZM",permalink:"https://ideaint.poetfarmer.com/country/zambia"},
  ],

  offices: [
    {latLng:["-1.29885","36.8285"],name:"Kenya","region_name":"Africa & West Asia","region_permalink":"https://ideaint.poetfarmer.com/region/africa-west-asia","sub_region":"SSA",country_code:"KE"},
    {latLng:["-25.9537","32.5887"],name:"Mozambique","region_name":"Africa & West Asia","region_permalink":"https://ideaint.poetfarmer.com/region/africa-west-asia","sub_region":"SSA",country_code:"MZ"},
    {latLng:["34.776","10.7449"],name:"Tunisia (North Africa and West Asia)","region_name":"Africa & West Asia","region_permalink":"https://ideaint.poetfarmer.com/region/africa-west-asia","sub_region":"NAWA",country_code:null},
    {latLng:["8.54112","39.2741"],name:"Africa and West Asia (Ethiopia)","region_name":"Africa & West Asia","region_permalink":"https://ideaint.poetfarmer.com/region/africa-west-asia","sub_region":null,country_code:null},
    {latLng:["3.40645","6.46542"],name:"Nigeria","region_name":"Africa & West Asia","region_permalink":"https://ideaint.poetfarmer.com/region/africa-west-asia","sub_region":"SSA",country_code:"NG"},
    {latLng:["8.48444","-13.2344"],name:"Sierra Leone","region_name":"Africa & West Asia","region_permalink":"https://ideaint.poetfarmer.com/region/africa-west-asia","sub_region":"SSA",country_code:"SL"},
    {latLng:["15.5085","32.5229"],name:"Sudan","region_name":"Africa & West Asia","region_permalink":"https://ideaint.poetfarmer.com/region/africa-west-asia","sub_region":"SSA",country_code:"SD"},
    {latLng:["-35.282","149.129"],name:"Asia and the Pacific (Australia)","region_name":"Asia & the Pacific","region_permalink":"https://ideaint.poetfarmer.com/region/asia-pacific","sub_region":null,country_code:"AU"},
    {latLng:["27.7","85.3333"],name:"Nepal","region_name":"Asia & the Pacific","region_permalink":"https://ideaint.poetfarmer.com/region/asia-pacific","sub_region":null,country_code:"NP"},
    {latLng:["26.8706","90.4856"],name:"Bhutan","region_name":"Asia & the Pacific","region_permalink":"https://ideaint.poetfarmer.com/region/asia-pacific","sub_region":null,country_code:"BT"},
    {latLng:["-12.0464","-77.0428"],name:"Peru","region_name":"Latin America & the Caribbean","region_permalink":"https://ideaint.poetfarmer.com/region/latin-america-caribbean","sub_region":null,country_code:"PE"},
    {latLng:["-25.2637","-57.5759"],name:"Paraguay","region_name":"Latin America & the Caribbean","region_permalink":"https://ideaint.poetfarmer.com/region/latin-america-caribbean","sub_region":null,country_code:"PY"},
    {latLng:["-33.4475","-70.6737"],name:"Chile","region_name":"Latin America & the Caribbean","region_permalink":"https://ideaint.poetfarmer.com/region/latin-america-caribbean","sub_region":null,country_code:"CL"},
    {latLng:["8.98333","-79.5167"],name:"Latin America and the Caribbean (Panama)","region_name":"Latin America & the Caribbean","region_permalink":"https://ideaint.poetfarmer.com/region/latin-america-caribbean","sub_region":null,country_code:"PA"},
    {latLng:["50.8503","4.35171"],name:"Europe (Belgium)","region_name":"Europe ","region_permalink":"https://ideaint.poetfarmer.com/region/europe","sub_region":null,country_code:"BE"},
    {latLng:["52.0787","4.28879"],name:"The Netherlands","region_name":"Europe ","region_permalink":"https://ideaint.poetfarmer.com/region/europe","sub_region":null,country_code:"NL"},
    {latLng:["40.7302","-73.9352"],name:"United States of America","region_name":"UN Office (New York)","region_permalink":"https://ideaint.poetfarmer.com/region/un-office-new-york","sub_region":null,country_code:"US"}

  ],

  thCountries: [
    {name:"Myanmar",themes:[{title:"Electoral Processes","color":"#D42C4E"},
{title:"Money in Politics","color":"#5DB3B2"},
{title:"Gender Equality and Inclusion","color":"#5383D7"}],country_code:"MM",permalink:"https://ideaint.poetfarmer.com/country/myanmar"},
    {name:"Mozambique",themes:[{title:"Electoral Processes","color":"#D42C4E"}],country_code:"MZ",permalink:"https://ideaint.poetfarmer.com/country/mozambique"},
    {name:"Sudan",themes:[{title:"Migration & Democracy","color":"#FFCC00"},
{title:"Political Parties","color":"#FDAF18"},
{title:"Money in Politics","color":"#5DB3B2"}],country_code:"SD",permalink:"https://ideaint.poetfarmer.com/country/sudan"},
  ],

  msCountries: [
    {name:"Netherlands",field_member_state:"Chair",field_year_joined:"1995",country_code:"NL",permalink:"https://ideaint.poetfarmer.com/country/netherlands"},
    {name:"Ghana",field_member_state:"First Vice Chair",field_year_joined:"2008",country_code:"GH",permalink:"https://ideaint.poetfarmer.com/country/ghana"},
    {name:"Sudan",field_member_state:"First Vice Chair",field_year_joined:"null",country_code:"SD",permalink:"https://ideaint.poetfarmer.com/country/sudan"},
    {name:"Paraguay",field_member_state:"First Vice Chair",field_year_joined:"null",country_code:"PY",permalink:"https://ideaint.poetfarmer.com/country/paraguay"},
    {name:"Chile",field_member_state:"First Vice Chair",field_year_joined:"1995",country_code:"CL",permalink:"https://ideaint.poetfarmer.com/country/chile"},
    {name:"Australia",field_member_state:"Yes",field_year_joined:"1995",country_code:"AU",permalink:"https://ideaint.poetfarmer.com/country/australia"},
    {name:"Tunisia",field_member_state:"Yes",field_year_joined:"2019",country_code:"TN",permalink:"https://ideaint.poetfarmer.com/country/tunisia"},
    {name:"Mexico",field_member_state:"Yes",field_year_joined:"2003",country_code:"MX",permalink:"https://ideaint.poetfarmer.com/country/mexico"},
    {name:"Peru",field_member_state:"Yes",field_year_joined:"2004",country_code:"PE",permalink:"https://ideaint.poetfarmer.com/country/peru"},
    {name:"Barbados",field_member_state:"Yes",field_year_joined:"1995",country_code:"BB",permalink:"https://ideaint.poetfarmer.com/country/barbados"},
    {name:"Belgium",field_member_state:"Yes",field_year_joined:"1995",country_code:"BE",permalink:"https://ideaint.poetfarmer.com/country/belgium"},
    {name:"Benin",field_member_state:"Yes",field_year_joined:"2016",country_code:"BJ",permalink:"https://ideaint.poetfarmer.com/country/benin"},
    {name:"Botswana",field_member_state:"Yes",field_year_joined:"1997",country_code:"BW",permalink:"https://ideaint.poetfarmer.com/country/botswana"},
    {name:"Brazil",field_member_state:"Yes",field_year_joined:"2016",country_code:"BR",permalink:"https://ideaint.poetfarmer.com/country/brazil"},
    {name:"Canada",field_member_state:"Yes",field_year_joined:"1997",country_code:"CA",permalink:"https://ideaint.poetfarmer.com/country/canada"},
    {name:"Cape Verde",field_member_state:"Yes",field_year_joined:"2003",country_code:"CV",permalink:"https://ideaint.poetfarmer.com/country/cape-verde"},
    {name:"Costa Rica",field_member_state:"Yes",field_year_joined:"1995",country_code:"CR",permalink:"https://ideaint.poetfarmer.com/country/costa-rica"},
    {name:"Dominican Republic",field_member_state:"Yes",field_year_joined:"2011",country_code:"DO",permalink:"https://ideaint.poetfarmer.com/country/dominican-republic"},
    {name:"Estonia",field_member_state:"Yes",field_year_joined:"2021",country_code:"EE",permalink:"https://ideaint.poetfarmer.com/country/estonia"},
    {name:"Finland",field_member_state:"Yes",field_year_joined:"1995",country_code:"FI",permalink:"https://ideaint.poetfarmer.com/country/finland"},
    {name:"Germany",field_member_state:"Yes",field_year_joined:"2002",country_code:"DE",permalink:"https://ideaint.poetfarmer.com/country/germany"},
    {name:"Panama",field_member_state:"Yes",field_year_joined:"2018",country_code:"PA",permalink:"https://ideaint.poetfarmer.com/country/panama"},
    {name:"India",field_member_state:"Yes",field_year_joined:"1995",country_code:"IN",permalink:"https://ideaint.poetfarmer.com/country/india"},
    {name:"Indonesia",field_member_state:"Yes",field_year_joined:"2013",country_code:"ID",permalink:"https://ideaint.poetfarmer.com/country/indonesia"},
    {name:"Japan",field_member_state:"Yes",field_year_joined:"null",country_code:"JP",permalink:"https://ideaint.poetfarmer.com/country/japan"},
    {name:"Luxembourg",field_member_state:"Yes",field_year_joined:"2018",country_code:"LU",permalink:"https://ideaint.poetfarmer.com/country/luxembourg"},
    {name:"Mauritius",field_member_state:"Yes",field_year_joined:"1999",country_code:"MU",permalink:"https://ideaint.poetfarmer.com/country/mauritius"},
    {name:"Mongolia",field_member_state:"Yes",field_year_joined:"2011",country_code:"MN",permalink:"https://ideaint.poetfarmer.com/country/mongolia"},
    {name:"Namibia",field_member_state:"Yes",field_year_joined:"1997",country_code:"NA",permalink:"https://ideaint.poetfarmer.com/country/namibia"},
    {name:"Norway",field_member_state:"Yes",field_year_joined:"1995",country_code:"NO",permalink:"https://ideaint.poetfarmer.com/country/norway"},
    {name:"Philippines",field_member_state:"Yes",field_year_joined:"2013",country_code:"PH",permalink:"https://ideaint.poetfarmer.com/country/philippines"},
    {name:"Portugal",field_member_state:"Yes",field_year_joined:"1995",country_code:"PT",permalink:"https://ideaint.poetfarmer.com/country/portugal"},
    {name:"South Africa",field_member_state:"Yes",field_year_joined:"1995",country_code:"ZA",permalink:"https://ideaint.poetfarmer.com/country/south-africa"},
    {name:"Spain",field_member_state:"Yes",field_year_joined:"1995",country_code:"ES",permalink:"https://ideaint.poetfarmer.com/country/spain"},
    {name:"Sweden",field_member_state:"Yes",field_year_joined:"1995",country_code:"SE",permalink:"https://ideaint.poetfarmer.com/country/sweden"},
    {name:"Switzerland",field_member_state:"Yes",field_year_joined:"2006",country_code:"CH",permalink:"https://ideaint.poetfarmer.com/country/switzerland"},
    {name:"United States",field_member_state:"Yes",field_year_joined:"null",country_code:"US",permalink:"https://ideaint.poetfarmer.com/country/united-states"},
    {name:"Uruguay",field_member_state:"Yes",field_year_joined:"2003",country_code:"UY",permalink:"https://ideaint.poetfarmer.com/country/uruguay"}
  ],
};

export const chartData = {
  mediaMentions: {
    data: [
      {country: ["United", "States"], count: 2490, color: '#050929'},
      {country: "Mexico", count: 1070, color: '#002656'},
      {country: "Spain", count: 811, color: '#0048A2'},
      {country: "Argentina", count: 697, color: '#005EB0'},
      {country: "Brazil", count: 485, color: '#0F91CE'},
      {country: "Peru", count: 397, color: '#71BFE7'},
      {country: "Indonesia", count: 201, color: '#3470BE'},
      {country: "Nigeria", count: 195, color: '#4F40AF'},
      {country: ["Dominican", "Republic"], count: 162, color: '#4AB5AF'},
      {country: "Colombia", count: 150, color: '#34C17C'},
      {country: "India", count: 145, color: '#363A48'},
      {country: ["South", "Africa"], count: 111, color: '#FC9F00'},
    ],
  },
};
