// Functions ///////////////////////////////////////////////////////////

export function getValidCountryCodes(mapObj, data) {
  const validCountryCodes = data.filter((country) => {
    return mapObj.regions[country.country_code] !== undefined;
  });

  return validCountryCodes;
}

// Check if country code is found in countries from data attribute
export function countryCodeFound(data, code) {
  const found = data.filter(item => {
    return item.country_code === code;
  })

  if (found.length > 0) { return true }

  return false;
}

// Get country name by country code
export function getCountryNameByCode(data, code) {
  const found = data.filter(item => {
    return item.country_code === code;
  })

  if (found.length > 0) { return found[0].name }

  return '';
}

// Get country object by country code
export function getCountryObjectByCode(data, code) {
  const found = data.filter(item => {
    return item.country_code === code;
  })

  if (found.length > 0) { return found[0] }

  return [];
}

// Append content to map tooltip
export function appendMapTooltip(tooltip, content) {
  const tip = tooltip;
  var spacing = '';
    
  tip.html('');

  if (content.title !== undefined) {
    tip.append('<div class="font-semibold map-tooltip--title">'+content.title+'</div>');
    spacing = 'mt-3';
  }

  if (content.body !== undefined && content.body.length > 0) {
    $.each(content.body, function(index) {
      const item = $(this)[0];
    
      tip.append('<div class="' + spacing + '">'+item.text+'</div>');
      
      if (item.link_url !== undefined) {
        tip.append('<div><a href="'+item.link_url+'" class="tema-text--link">'+item.link_text+'</a></div>');
      }
    });
  }

  if (content.footer !== undefined) {
    tip.append('<div class="text-xs">'+content.footer+'</div>');
  }
}

// Mod map to add borders etc.
export function modMap(mapElm) {
  // Western Sahara custom border ///////////////////////////////////////////
  var borderPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path"
  );

  borderPath.setAttributeNS(null, "d", "M392.75 211.70 l6.7 0");
  borderPath.setAttributeNS(null, "stroke", "white");
  borderPath.setAttributeNS(null, "stroke-width", "0.5");
  borderPath.setAttributeNS(null, "stroke-dasharray", "0.5, 0.5");

  mapElm.find("svg").find("g").first().append(borderPath);

  // India Pakistan custom border
  borderPath = document.createElementNS("http://www.w3.org/2000/svg", "path");

  borderPath.setAttributeNS(
    null,
    "d",
    "M609 201 l2 -0.5, 0.5 -1, 1.5 -0.5, 1.5 0.75, 1.5 0, 1.5 1"
  );
  borderPath.setAttributeNS(null, "stroke", "white");
  borderPath.setAttributeNS(null, "stroke-width", "0.5");
  borderPath.setAttributeNS(null, "stroke-dasharray", "0.5, 0.5");
  borderPath.setAttributeNS(null, "fill", "transparent");

  mapElm.find("svg").find("g").first().append(borderPath);

  borderPath = document.createElementNS("http://www.w3.org/2000/svg", "path");

  borderPath.setAttributeNS(
    null,
    "d",
    "M607 199.75 l-2.25 -1.75, -0.75 -1.75, 0 -2.25, 1.75 -0.75, -1.5 -2, -0.75 0, 0 -1, 2 -2"
  );
  borderPath.setAttributeNS(null, "stroke", "white");
  borderPath.setAttributeNS(null, "stroke-width", "0.5");
  borderPath.setAttributeNS(null, "stroke-dasharray", "0.5, 0.5");
  borderPath.setAttributeNS(null, "fill", "transparent");

  mapElm.find("svg").find("g").first().append(borderPath);

  borderPath = document.createElementNS("http://www.w3.org/2000/svg", "path");

  borderPath.setAttributeNS(null, "stroke", "#d8d8d8");
  borderPath.setAttributeNS(
    null,
    "d",
    "M607.5 199.75 l-2.0 -4.3, 1.5 -1.3, 4 0.85, 2.5 -0.5, 2 -1.75"
  );
  borderPath.setAttributeNS(null, "stroke-width", "1.0");
  borderPath.setAttributeNS(null, "stroke-dasharray", "0.5, 0.5");
  borderPath.setAttributeNS(null, "fill", "transparent");

  mapElm.find("svg").find("g").first().append(borderPath);
}

// Variables ///////////////////////////////////////////////////////////

export const markerStyle = {
  initial: {
    fill: '#F38336',
    stroke: "white",
    "stroke-width": 5,
    r: 10,
  },
  hover: {
    stroke: 'white',
    fill: '#F38336',
    "stroke-width": 5,
    r: 12,
    cursor: 'pointer'
  },
}

export const regionStyle = {
  initial: {
    fill: "#C1C7D3",
    "fill-opacity": 1,
    stroke: "none",
    "stroke-width": 0,
    "stroke-opacity": 1,
  },
}