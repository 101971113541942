jQuery(function ($) {
  const impactStoriesTab = $("#impact-tabs");
  impactStoriesTab.vTabs();

  const impactSlides = [];
  $(".impact-slides").each(function () {
    const tabId = $(this).closest(".vtab-content").attr("id");
    const impactSlider = $(this).slick({
      // infinite: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      arrows: true,
      dots: true,
      // adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    impactSlides.push({
      tabId: "#" + tabId,
      slider: impactSlider,
    });
  });

  // refresh slick slider on tab change
  $(".vtab-toggle").on("click", function () {
    const tabId = $(this).data("target");
    const tabTarget = $(tabId);

    if (tabTarget.hasClass("active")) {
      const currentTabData = impactSlides.find((item) => item.tabId === tabId);
      if (currentTabData) {
        currentTabData.slider.slick("refresh");
      }
    }
  });

  const modalGallerySlider = [];
  $(".modal-gallery-slides").each(function (index) {
    const elm = $(this);

    const modalSlider = elm.slick({
      autoplay: false,
      slidesToShow: 1,
      arrows: true,
      dots: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          },
        },
      ],
    });

    modalGallerySlider.push({
      modalId: "#" + elm.closest(".modal-wrapper").attr("id"),
      slider: modalSlider,
    });
  });

  $(".button-open-modal").on("click", function () {
    const modalId = $(this).data("target");
    const modalSpeed = 600;
    const currentModalData = modalGallerySlider.find(
      (item) => item.modalId === modalId,
    );

    if (currentModalData) {
      setTimeout(() => {
        currentModalData.slider.slick("refresh");
      }, modalSpeed);
    }
  });
});
