jQuery(function ($) {
  $('.idea-slider').slick({
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1
        }
      },
    ]
  });
});
