import {
  Chart,
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle,
} from "chart.js";

Chart.register(
  ArcElement,
  LineElement,
  BarElement,
  PointElement,
  BarController,
  BubbleController,
  DoughnutController,
  LineController,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  RadialLinearScale,
  TimeScale,
  TimeSeriesScale,
  Decimation,
  Filler,
  Legend,
  Title,
  Tooltip,
  SubTitle
);

import {chartData} from './data';

let charts = [];

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "#ffffff";
    tooltipEl.style.borderRadius = "3px";
    tooltipEl.style.color = "#222222";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";

    const table = document.createElement("table");
    table.style.margin = "0px";

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  const chartType = context.chart._metasets[0].type;

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const tableRoot = tooltipEl.querySelector("table");
    tableRoot.style.fontSize = "14px";
    tableRoot.style.textAlign = "center";

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    if (chart.canvas.id === "chart-media-mentions") {
      tableRoot.innerHTML =
        '<tr><td class="font-semibold">' +
        tooltip.dataPoints[0].formattedValue +
        " Mentions</td></tr>";
    } else {
      const thisValue = tooltip.dataPoints[0].parsed;
      const totalValues = tooltip.dataPoints[0].dataset.data.reduce(
        (a, b) => parseFloat(a) + parseFloat(b),
        0
      );
      const percentageValue = ((thisValue / totalValues) * 100).toFixed(2);

      if (chartType === 'pie') {
        tableRoot.innerHTML =
          "<tr><td>" +
          tooltip.dataPoints[0].label +
          '</td></tr><tr><td class="font-semibold">' +
          tooltip.dataPoints[0].formattedValue +
          " (" +
          percentageValue +
          "%)</td></tr>";
      } else {
        tableRoot.innerHTML =
          '<tr><td class="font-semibold">' +
          tooltip.dataPoints[0].formattedValue + "</td></tr>";
      }
    }
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding =
    tooltip.options.padding + "px " + tooltip.options.padding + "px";
  tooltipEl.classList.add("drop-shadow-md");
};
// externalTooltipHandler END ///////////////////////////////////////////

jQuery(function ($) {
  console.log('chartData', chartData);

  if ($(".chart-outreach").length > 0) {
    $(".chart-outreach").each(function (index) {
      const data = $(this).data("data");
      const colors = $(this).data("colors");
      const labels = $(this).data("labels");

      // console.log('data, colors, labels', data, colors, labels);

      charts[index] = new Chart($(this), {
        type: "pie",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Chart " + index,
              data: data,
              backgroundColor: colors,
              borderWidth: 0,
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: externalTooltipHandler,
            },
          },
        },
      });
    });
  }

  if ($(".media-mentions-chart").length > 0) {
    const data = chartData.mediaMentions.data;

    // Desktop
    const mediaMentionsChartDesktop = new Chart(
      document.getElementById('media-mentions-desktop'),
      {
        type: 'bar',
        data: {
          labels: data.map(row => row.country),
          datasets: [
            {
              label: 'Media mentions',
              data: data.map(row => row.count),
              backgroundColor: data.map(row => row.color),
              barThickness: 34,
            }
          ]
        },
        options: {
          aspectRatio: 3,
          responsive: true,
          scales: {
            y: {
              ticks: {
                display: false
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              }
            },
            x: {
              ticks: {
                display: true,
                autoSkip: false,
                maxRotation: 0,
                minRotation: 0
              },
              grid: {
                display: false
              },
              border: {
                display: false,
              }
            },
          },
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: externalTooltipHandler,
            }
          },
        }
      }
    );

    // Mobile
    const mediaMentionsChartMobile = new Chart(
      document.getElementById('media-mentions-mobile'),
      {
        type: 'bar',
        data: {
          labels: data.map(row => row.country),
          datasets: [
            {
              label: 'Media mentions',
              data: data.map(row => row.count),
              backgroundColor: data.map(row => row.color),
              barThickness: 24,
            }
          ]
        },
        options: {
          indexAxis: 'y',
          scales: {
            y: {
              ticks: {
                display: true
              },
              grid: {
                display: false,
              },
              border: {
                display: false,
              }
            },
            x: {
              ticks: {
                display: false
              },
              grid: {
                display: false
              },
              border: {
                display: false,
              }
            }
          },
          maintainAspectRatio: false,
          borderRadius: 0.5,
          // barPercentage: 0.5,
          // categoryPercentage: 1,
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              enabled: false,
              position: "nearest",
              external: externalTooltipHandler,
            }
          }
        }
      }
    );

  }
});
