import {
  getValidCountryCodes,
  countryCodeFound,
  getCountryObjectByCode,
  appendMapTooltip,
  modMap,
  markerStyle,
  regionStyle} from './helper';

import { mapData } from './data';

jQuery(function($){
  if ($('#home-worldmap').length > 0) {
    // const baseUrl = $('#base-url').data('base-url');

    console.log('home-worldmap Ready');

    var theMap = $("#home-worldmap");
    const selectTheme = $('#select-themes');
    const markerOverlay = $('#marker-overlay');
    const tip = $(".map-tooltip");

    // Activity & Reach
    const arCountriesData = mapData.arCountries;
    const officesData = mapData.offices;
    // Themes
    const thCountriesData = mapData.thCountries;
    // Member State
    const msCountriesData = mapData.msCountries;

    // console.log('arCountriesData', arCountriesData);
    // console.log('officesData', officesData);
    // console.log('thCountriesData', thCountriesData);
    // console.log('msCountriesData', msCountriesData);

    let allThemes = thCountriesData.reduce((acc, curr) => acc.concat(curr.themes), []);
    // console.log('allThemes', allThemes);
    // let uniqueThemes = Array.from(new Set(allThemes.map(obj => {obj})));
    let uniqueThemes = [...new Set(allThemes.map(theme => JSON.stringify(theme)))].map(themeStr => JSON.parse(themeStr));

    // console.log('uniqueThemes', uniqueThemes);

    uniqueThemes.forEach(theme => {
      selectTheme.append('<option value="'+theme.title+'">'+theme.title+'</option>');
    });

    let mapType = "AR"; // AR = Activity & reach, TH = Themes, MS = Member states
    let activeCountriesData;

    // console.log('arCountriesData', arCountriesData);
    // console.log('officesData', officesData);

    theMap.vectorMap({
      map: "world_mill",
      backgroundColor: "#F9F9FB",
      panOnDrag: true,
      zoomAnimate: true,
      zoomOnScroll: false,
      // markers: officesData,
      markerStyle: markerStyle,
      focusOn: {
        x: 0.5,
        y: 0.5,
        scale: 1,
      },
      regionStyle: regionStyle,
      onRegionClick: function (event, code) {
        return;
        const countryObj = getCountryObjectByCode(activeCountriesData, code);

        window.location = countryObj.permalink;
      },
      onRegionOver: function (e, code) {
        e.preventDefault();

        if (countryCodeFound(activeCountriesData, code)) {
          const countryObj = getCountryObjectByCode(activeCountriesData, code);

          let content = {};

          content.title = countryObj.name;
          content.body = [];
          // content.footer = '(Click to view country page)';

          // tip.html('');

          // tip.append('<div class="font-semibold map-tooltip--title">'+countryObj.name+'</div>');

          if (mapType === 'AR') {
            content.body.push({'text': countryObj.project_classification});
            // tip.append('<div>'+countryObj.project_classification+'</div>');
          }

          if (mapType === 'TH') {
            let themes = '';
            countryObj.themes.forEach(theme => {
              // tip.append('<div>'+theme.title+'</div>');
              themes+='<div>'+theme.title+'</div>';
            });
            content.body.push({'text': themes});
          }

          if (mapType === 'MS') {
            if (countryObj.field_member_state === 'Yes') {
              content.body.push({'text': 'Member State'});

              // tip.append('<div>Member State</div>');
            } else {
              content.body.push({'text': 'Member State - '+countryObj.field_member_state});

              // tip.append('<div>Member State - '+countryObj.field_member_state+'</div>');
            }
          }

          // tip.append('<div class="text-xs">(Click to view country page)</div>');

          appendMapTooltip(tip, content);

          tip.css('position', 'fixed');
          tip.show();

          e.target.addEventListener("mousemove", tooltipMousemove);
        }
      },
      onRegionOut: function (e, code) {
        e.preventDefault();

        tip.hide();

        e.target.removeEventListener('mousemove', tooltipMousemove);
      },
      onRegionTipShow: function (e) {
        e.preventDefault();
        return false;
      },
      onViewportChange: function (e, scale) {
        // console.log('onViewportChange', e.target, 'scale', scale);
      },
      onMarkerTipShow: function(e, o, s) {
        // console.log('e o s', e, o, s);
        e.preventDefault();
      },
      onMarkerClick: function(e, index){
        return;

        var markerElement = $('circle[data-index='+index+']');
        var rect = markerElement[0].getBoundingClientRect();
        var x = rect.left + window.pageXOffset;
        var y = rect.top + window.pageYOffset;

        // let top = window.pageYOffset || document.documentElement.scrollTop;

        let content = {};

        content.title = officesData[index].name;
        content.body = [];
        // content.body.push({
        //   'text': officesData[index].region_name,
        //   'link_url': officesData[index].region_permalink,
        //   'link_text': 'View regional page',
        // });

        // if (officesData[index].sub_region !== null) {
        //   if (officesData[index].sub_region === 'NAWA') {
        //     content.body.push({
        //       'text': 'North Africa and West Asia',
        //       'link_url': NawaPageUrl,
        //       'link_text': 'View sub-regional page',
        //     });
        //   } else {
        //     content.body.push({
        //       'text': 'Sub-Saharan Africa',
        //       'link_url': SsaPageUrl,
        //       'link_text': 'View sub-regional page',
        //     });
        //   }
        // }

        appendMapTooltip(tip, content);

        // tip.css("top", y - top + "px");
        tip.css("top", y + "px");
        tip.css("left", x + (rect.width / 2) + "px");

        markerOverlay.fadeIn();
        tip.css('position', 'absolute');
        tip.show();
        e.preventDefault();
      },
      onMarkerOver: function(e, index ) {
        e.preventDefault();

        var markerElement = $('circle[data-index='+index+']');
        var rect = markerElement[0].getBoundingClientRect();
        var x = rect.left + window.pageXOffset;
        var y = rect.top + window.pageYOffset;

        // let top = window.pageYOffset || document.documentElement.scrollTop;

        let content = {};

        content.title = officesData[index].name;
        content.body = [];
        // content.body.push({
        //   'text': officesData[index].region_name,
        //   'link_url': officesData[index].region_permalink,
        //   'link_text': 'View regional page',
        // });

        // if (officesData[index].sub_region !== null) {
        //   if (officesData[index].sub_region === 'NAWA') {
        //     content.body.push({
        //       'text': 'North Africa and West Asia',
        //       'link_url': NawaPageUrl,
        //       'link_text': 'View sub-regional page',
        //     });
        //   } else {
        //     content.body.push({
        //       'text': 'Sub-Saharan Africa',
        //       'link_url': SsaPageUrl,
        //       'link_text': 'View sub-regional page',
        //     });
        //   }
        // }

        appendMapTooltip(tip, content);

        // tip.css("top", y - top + "px");
        tip.css("top", y + "px");
        tip.css("left", x + (rect.width / 2) + "px");

        // markerOverlay.fadeIn();
        tip.css('position', 'absolute');
        tip.show();

        ////////////////////////////////////////////////

        const countryCode = officesData[index].country_code;

        if (countryCode === null) { return; }

        myMap.clearSelectedRegions();

        const validCountryCodes = getValidCountryCodes(myMap, arCountriesData);

        validCountryCodes.map((country, index) => {
          if (country.country_code === countryCode) {
            myMap.regions[country.country_code].element.config.style.selected.fill = '#F38336';
          } else {
            myMap.regions[country.country_code].element.config.style.selected.fill = getARRegionColor(country.project_classification);
          }
          myMap.setSelectedRegions(country.country_code);
        });
      },
      onMarkerOut: function(e, index) {
        e.preventDefault();

        const countryCode = officesData[index].country_code;

        if (countryCode === null) { return; }

        myMap.clearSelectedRegions();

        const validCountryCodes = getValidCountryCodes(myMap, arCountriesData);

        validCountryCodes.map((country, index) => {
          myMap.regions[country.country_code].element.config.style.selected.fill = getARRegionColor(country.project_classification);
          myMap.setSelectedRegions(country.country_code);
        });
      }
    });
    // theMap.vectormap END ////////////////////////////////////////////////////

    modMap(theMap);

    var myMap = theMap.vectorMap("get", "mapObject");

    // Get highlight color for Activity & Reach type
    function getARRegionColor(pc) {
      switch (pc) {
        case 'Existing and planned projects':
          return '#004587';

        // case 'Inspiring and Supporting Activities':
        //   return '#398DC8';

        case 'Reach':
          return '#95C2E5';

        default:
          return '#C1C7D3';
      }
    }

    // Get highlight color for Theme
    function getThemeColor(theme) {
      let color = '#004587';

      uniqueThemes.forEach(item => {
        if (item.title === theme) {
          color = item.color;
        }
      });

      return color;
    }

    // Select/highlight all countries in current map type
    function selectAllCountries(color = '#004587') {
      const validCountryCodes = getValidCountryCodes(myMap, activeCountriesData);

      validCountryCodes.map((country, index) => {
        myMap.regions[country.country_code].element.config.style.selected.fill = color;
        myMap.setSelectedRegions(country.country_code);
      });
    }

    // Select/highlight countries by theme
    function selectThemeCountries(c, t) {
      let selectedThemeCountries = [];

      c.map(country => {
        const themes = country.themes;

        themes.map(theme => {
          if (theme.title === t) {
            selectedThemeCountries.push(country);
          }
        });
      });

      console.log('selectedThemeCountries', selectedThemeCountries);

      const fillColor = getThemeColor(t);

      selectedThemeCountries.map((country, index) => {
        myMap.regions[country.country_code].element.config.style.selected.fill = fillColor;
        myMap.setSelectedRegions(country.country_code);
      });

      activeCountriesData = selectedThemeCountries;
    }

    // Map init for Activity & Reach
    function initARMap() {
      const validCountryCodes = getValidCountryCodes(myMap, arCountriesData);

      validCountryCodes.map((country, index) => {
        myMap.regions[country.country_code].element.config.style.selected.fill = getARRegionColor(country.project_classification);
        myMap.setSelectedRegions(country.country_code);
      });

      activeCountriesData = arCountriesData;
      myMap.addMarkers(officesData);
    }

    // Map init for Themes
    function initTHMap() {
      const selectedTheme = selectTheme.val();

      if (selectedTheme === '') {
        activeCountriesData = thCountriesData;
        selectAllCountries();
      } else {
        selectThemeCountries(thCountriesData, selectedTheme);
      }
    }

    // Map init for Member State
    function initMSMap() {
      activeCountriesData = msCountriesData;
      selectAllCountries('#36A0E7');
    }

    // Handler for theme select list
    selectTheme.on('change', function() {
      const selectedTheme = $(this).val();

      myMap.clearSelectedRegions();

      if (selectedTheme === '') {
        activeCountriesData = thCountriesData;
        selectAllCountries();
      } else {
        selectThemeCountries(thCountriesData, selectedTheme);
      }
    });

    // Position map tooltip
    function tooltipMousemove(e) {
      let top = window.pageYOffset || document.documentElement.scrollTop;

      tip.css("top", e.pageY - top + "px");
      tip.css("left", e.pageX + "px");
    }

    markerOverlay.on('click', function(){
      $(this).fadeOut();
      tip.hide();
    });

    // Map type buttons click event handler, to change map type
    $('.btn-home--map---type').on('click', function(){
      const elm = $(this);
      if (mapType === elm.data('type')) { return }

      mapType = elm.data('type');

      $('.btn-home--map---type').removeClass('active');
      elm.addClass('active');

      myMap.clearSelectedRegions();
      myMap.removeAllMarkers();

      switch(mapType) {
        case 'AR':
          initARMap();
          break;

        case 'TH':
          initTHMap();
          break;

        case 'MS':
          initMSMap();
          break;

        default:
          break;
      }

      $('.map-legend').hide();
      $('#map-legend-' + mapType).show();
    });

    // On page load init map type Activity & Reach
    initARMap();
  } // if END
});
